import React from "react";
import "./style.scss";
import Header from "../header";
import { Link } from "react-router-dom";

// import IMAGES from "../../assets/index";

function LeaveRequset() {
    return (
        <div className="wrapper_section">
            <Header title="CODALIEN" icon="person" secondIcon="question_mark" class1="col-6" class2="col-6" />
            <div className="request_section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                            <div className="apply_for_leave">
                                <h2 className="mb-4">Apply For Leave</h2>
                                <div class="request-leave-box px-4 py-4" >
                                        <input className="input_field  mb-3" type="date" data-role="calendarpicker" />
                                    <textarea className="input_field" rows="5" placeholder="Leave reason"></textarea>
                                </div>
                                <div className="btn-container mt-5 px-3">
                                    <button className="sign_in_btn"><Link className="text-white" to="/">Submit</Link></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LeaveRequset;
