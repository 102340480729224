import CryptoJS from 'crypto-js';
//const CryptoJS =require("crypto-js");

const str=process.env.REACT_APP_SECRET_KEY;

export default class StatelessMiddleware{
//module.exports=class StatelessMiddleware{
    
    static async encrypt(obj) {
        const data=JSON.stringify(obj);
        try {  
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data),str).toString();
            return ciphertext;
            // let key=crypto.scryptSync(str,'salt',24);
            // let ivkey=crypto.scryptSync(str,'salt',16);
            // const cipher=crypto.createCipheriv(algorithm, key, ivkey);
            // //let encrypted = '';
            // // cipher.setEncoding('hex');
            // return cipher.update(data, "binary", "hex") + cipher.final("hex");
            //cipher.on('data', (chunk) => encrypted += chunk);
            // cipher.on('end', async() =>{ console.log(encrypted);  return encrypted;});// Prints encrypted data with key
            // cipher.end();
        } catch (error) {
            console.log("stateless err1");
            throw new Error(error);
        }
    }

    static async generateAccessToken(data) {
        try {
          let tok=await StatelessMiddleware.encrypt(data);
          return tok;
        } catch (error) {
          throw new Error(error);
        }
    }

    static async decrypt(token){
        try{
            // let ivkey=crypto.scryptSync(str,'salt',16);
            // let key=crypto.scryptSync(str,'salt',24);
            // const decipher=crypto.createDecipheriv(algorithm,key,ivkey);
            // let d=decipher.update(token,"hex")+decipher.final();
            var bytes  = CryptoJS.AES.decrypt(token, str);
            var d= JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return d;
        }catch(err){
            throw new Error(err);
        }
    }
}