import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import Header from "../header";
import BookingService from "../../services/bookingService";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/userService";
import RoomService from "../../services/roomService";
import Spinner from 'react-bootstrap/Spinner';
//import BookingValidator from "../../validations/Booking";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import AuthenticationService from "../../services/authenticationService";
// import IMAGES from "../../assets/index";

function Booking() {

  const location = useLocation();
  const { meetingroom } = location.state;
  console.log(meetingroom);
  const [name,setName]=useState("")
  const [data, setData] = useState({});
  const [user, setUser] = useState({});
  const [room,setRoom]= useState({});
  const [hour, Sethour] = useState(12);
  const [min, Setmin] = useState(0);
  const [active, Setactive] = useState(1);
  const [date, Setdate] = useState("");
  const [time, Settime] = useState("");
  const [subject, Setsubject] = useState("");
  const [Agenda, Setagenda] = useState("");
  const [MeetingType, Setmeetingtype] = useState("Interview Meeting");
  const [Duration,SetDuration]=useState(15);
  const [validationResult,Setvalidationresult]=useState({});
  const [show, setShow] = useState(false);
  const [email,setEmail]= useState(sessionStorage.getItem('currentloggedin'));
  const [errorMsg,setErrorMsg]=useState("some error occured while logging in");

  const navigate=useNavigate();
  useMemo(() => {
    function createtiming() {
      let hh = hour;
      let mm = min;
      if(active===2 && hh!=12){
        hh=parseInt(hh)+12;
      }else if(active==1 && hh==12){
         hh=parseInt(hh)%12;
      }
      if (hh < 10) {
        hh = `0${hh}`;
      }
      if (mm < 10) {
        mm = `0${mm}`;
      }
    
      console.log(`${date}T${hh}:${mm}:00.000Z`);
      Settime(`${date}T${hh}:${mm}:00.000Z`)
    } 
   createtiming();
    
    
  },[hour, min, date,active]);

  useEffect(() => {
  
   async function getUserEmail(){
      try {
        let Sessiontoken=sessionStorage.getItem('accessToken');
        const isAuthenticated=await AuthenticationService.checkAuthentication(Sessiontoken);
        console.log(isAuthenticated);
        if (isAuthenticated===true){
          const user=await AuthenticationService.getUserDataByToken(Sessiontoken);
          setEmail(user.username);
          return user;
        }else{
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 1000);}
      }catch(err){
        setErrorMsg(`error while signing in.. `);
        setShow(true);
      }
   }
    
    async function getRoomByRoomNo(roomno){
      try {
        const res = await RoomService.getRoomByRoomNo(roomno);
        setRoom(res);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
    async function getuser(email){
      try {
        const res = await UserService.getUserByUsername(email);
        setUser(res);
        setName(res.user.name);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    } 
    
    getUserEmail().then((user)=>{
      getuser(user.username);
    }).catch(err=>{
      throw new Error(err);})
    getRoomByRoomNo(meetingroom);                                   //to populate the room data
                                           //to get the user              
    if(user&&user.user){
      console.log(user.user.name);
    }
  },[]);


  async function handleClick(){
    let flag=await validatebooking();
    console.log(flag) 
    if (flag){
      createbooking();
    } else{
      setShow(true);
    }
  }


const validatebooking= async ()=>{
  try{
   return Promise.resolve(BookingService.checkavailabilty(Duration,time,meetingroom).then((data)=>{
      console.log(data);
      Setvalidationresult(data);
      if(!data.isValid){
        console.log("not ok")
        console.log(data.status)
        return false;
      } else{
        console.log("ok")
        console.log(data.status)
        return true;
      }
    })); 
  }catch(e){
     console.log("erere",e)
  }
 }

  async function createbooking(){
    try {
      const res = await BookingService.createbooking(
        name,
        Agenda,
        email,
        "9494949491",
        Duration,
        time,
        subject,
        meetingroom,
        MeetingType
      );
      setData(res);
      console.log(data);
      console.log("booking created");
      window.location.replace("/meetingschedule");
    } catch (error) {
      console.log(error);
      setErrorMsg(error);
    }
  }

  

  return (
    <div className="wrapper_section position-relative">
      <Header title="CODALIEN" icon="person" secondIcon="question_mark" class1="col-6" class2="col-6" />
      <main className="booking_section">
        <div class="container pt-5">
          <ToastContainer className="position-fixed" position="top-end">
            <Toast onClose={()=>{ setShow(false)}} show={show} delay={3000} autohide bg="Primary ">
                <Toast.Header style={{backgroundColor:"red", color:"white"
            }}>
                    <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                    />
                    <strong className="me-auto">Booking Failed</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{validationResult.status ? validationResult.status : "can't create booking"}</Toast.Body>
            </Toast>
          </ToastContainer>
          <div className="row">
            { !room || !room.rooms || room.rooms.length<=0 ? (
                <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              ):(
              <div className="col-12">
                <div
                class="book-room-img-box mb-3"
                style={{ backgroundImage: `url(${room.rooms[0].image.url})` }} //{room.rooms.image.url}
              ></div>

              <div className="booking_info_box mt-5 pb-5">
                <h3 class="book-room-heading">{room.rooms[0].Meeting_Room_Name}</h3>     {/*{room.rooms.image.url}*/}

                <p class="book-room-text">{room.rooms[0].floor}</p>   {/*{room.rooms.Meeting_Room}, {room.rooms.floor}*/}
                <p class="book-room-text">Max &#58; {room.rooms[0].maxPersonAllowed} persons</p>            {/*{room.rooms.maxPersonAllowed}*/}
                </div>
                </div>
            )}
              
              <div className="booking_info_box mt-5 pb-5">
                
                <div class="date-time-box row justify-content-center">
                  <div className="col-lg-6 col-md-10 col-sm-12 col-12">
                    <div className="time_section mt-4 px-lg-5 px-md-5 px-sm-0 px-0">
                      <label class="time-text text-center">Date</label>

                      <input
                        class="input_field select_date mt-4"
                        type="date"
                        placeholder="Select Date"
                        onChange={(e) => {
                          Setdate(e.target.value);
                          console.log(date);
                        }}
                        id="date"
                      />
                    </div>
                    <div className="time_section mt-4 px-lg-5 px-md-5 px-sm-0 px-0">
                      <label class="time-text text-center">Time</label>
                      <div class="booking-time-box d-flex align-items-center justify-content-center mt-4">
                        <div class="time-box ms-0">
                          <button
                            onClick={() => {
                              Sethour(((hour - 1 - 12) % 12) + 12);
                            }}
                          >
                            <span class="upward-arrow material-symbols-outlined white-color text-white">
                              expand_less
                            </span>
                          </button>

                          <label class="text-white d-block">{`${hour}`}</label>

                          <button
                            onClick={() => {
                              Sethour((hour % 12) + 1);
                            }}
                          >
                            <span class="downward-arrow text-center material-symbols-outlined white-color text-white">
                              expand_more
                            </span>
                          </button>
                        </div>
                        <div class="colon-box">
                          <span> &#58;</span>
                        </div>

                        <div class="time-box">
                          <button
                            onClick={() => {
                              Setmin((((min - 5 - 60) % 60) + 60) % 60);
                            }}
                          >
                            <span class="material-symbols-outlined white-color text-white">
                              expand_less
                            </span>
                          </button>

                          <label class="time text-center white-color text-white d-block">{`${min}`}</label>

                          <button
                            onClick={() => {
                              Setmin((min + 5) % 60);
                            }}
                          >
                            <span class="downward-arrow material-symbols-outlined white-color text-white">
                              expand_more
                            </span>
                          </button>
                        </div>

                        <div class="am-pm-box ms-2">
                          <button
                            onClick={() => {
                              Setactive(1);
                            }}
                            className={`d-block border border-0 ${
                              active === 1 ? "active" : ""
                            }`}
                          >
                            <span class="time mb-0">AM</span>
                          </button>

                          <div class="border_icon"></div>
                          <button
                            onClick={() => {
                              Setactive(2);
                            }}
                            className={`d-block border border-0 ${
                              active === 2 ? "active" : ""
                            }`}
                          >
                            <span class=" time mb-0">PM</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 px-lg-5 px-md-5 px-sm-0 px-0">
                      <label className="time-text text-center">Duration</label>
                      <select
                        name="duration"
                        id="duration"
                        className="input_field select_date my-3"
                        onChange={(e)=>{
                          SetDuration(e.target.value)
                          console.log(Duration);
                        }}
                      >
                        <option value="15">15 min</option>
                        <option value="30">30 min</option>
                        <option value="45">45 min</option>
                        <option value="60">60 min</option>
                        <option value="75">75 min</option>
                        <option value="90">90 min</option>
                        <option value="105">105 min</option>
                        <option value="120">120 min</option>
                      </select>
                    </div>
                    <div className="mt-4 px-lg-5 px-md-5 px-sm-0 px-0">
                      <label className="time-text text-center">
                        Meeting Type
                      </label>
                      <select
                        name="duration"
                        id="duration"
                        className="input_field select_date my-3"
                        onChange={(e)=>{
                         console.log(e.target.value)
                         Setmeetingtype(e.target.value)}}
                      >
                        <option value="Interview Meeting">
                          Interview Meeting
                        </option>
                        <option value="Buisness Meeting">
                          Buisness Meeting
                        </option>
                        <option value="Design Meeting">Design Meeting</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div class="mt-5 px-lg-5 px-md-5 px-sm-0 px-0">
                      <input
                        class="input_field select_date mt-4"
                        type="text"
                        onChange={(e) => {
                          Setsubject(e.target.value);
                          console.log(subject);
                        }}
                        placeholder="Subject"
                        id="subject"
                      />
                    </div>
                    <div class="mt-5 px-lg-5 px-md-5 px-sm-0 px-0">
                      <input
                        class="input_field select_date mt-4"
                        type="text"
                        onChange={(e) => {
                          Setagenda(e.target.value);
                          console.log(Agenda);
                        }}
                        placeholder="Agenda"
                        id="agenda"
                      />
                    </div>
                    <div class="mt-5 px-lg-5 px-md-5 px-sm-0 px-0">
                
                        <button
                          type="button"
                          className="book-room-button-box input_field select_date w-100"
                          onClick={handleClick}
                        >
                          Book Room
                        </button>
                      
                    </div>
                  </div>
                </div>
              </div> 
            </div>
        </div>
      </main>
    </div>
  );
}
export default Booking;
