import './App.css';
import SplashScreen from './Components/splashScreen';
import CreateAccount from './Components/createAccount'
import Login from './Components/login/login';
import MeetingRoom from './Components/meetingRoom';
import Schedule from './Components/Schedule';
import {Route , Routes } from 'react-router-dom';
import EmployeeLogin from './Components/empLogin'
import ChoiceCards from './Components/choiceCards'
import Attendance from "./Components/attendance"
import LeaveRequset from "./Components/leaveRequest"
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/custom.scss"
import Booking from './Components/booking';
import Interview from './Components/meetingDetails';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route exact path='/' element = {<SplashScreen/>}></Route>
          <Route exact path='/login' element = {<Login/>}></Route>
          <Route exact path='/emplogin' element = {<EmployeeLogin/>}></Route>
          <Route exact path='/createaccount' element = {<CreateAccount/>}></Route>
          <Route exact path='/meetingroom' element = {<MeetingRoom/>}></Route>
          <Route exact path='/meetingschedule' element={<Schedule/>}></Route>
          <Route exact path='/meetingdetails' element={<Interview/>}></Route>
          <Route exact path='/bookrooms' element={<Booking/>}></Route>
          <Route exact path='/choicecards' element={<ChoiceCards/>}></Route>
          <Route exact path='/attendance' element={<Attendance/>}></Route>
          <Route exact path='/leaverequest' element={<LeaveRequset/>}></Route>
        </Routes>
    </div>
  );
}

export default App;
