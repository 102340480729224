import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
// import CompanyLogo from "../../assets/img/logo.png";

// import IMAGES from "../../assets/index";

function Header(props) {
 const navigate=useNavigate();
  const navigateLogin = () => {
    navigate('/login');
  };

function handlelogout(){
  sessionStorage.removeItem("accessToken");
  window.location.replace('/login');
}

  return (
    <header className="header stickyHeader">
      <div className="container">
        <div className="row justify-content-between align-items-center py-3">
          <div className={`heading-box ${props.class1}`}>
            <h3 className="header-heading">{props.title}</h3>
          </div>
          <div className={`user-icon-box ${props.class2}`}>
            <button className="header-button border border-0" onClick={()=>{navigateLogin()}}>
            {props.icon &&<span className="material-symbols-outlined"> 
             {props.icon}
              </span>}
            </button>
            <button className="header-button border border-0">
              {props.secondIcon&&<span className="material-symbols-outlined fa-question-mark">
                 {props.secondIcon}
                 </span>}
            </button>
            <button className="header-button border border-0 logoutbtn" onClick={handlelogout}>
             logout  
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;