import React from "react";
import "./style.scss";
import MobilePhone from "../../assets/img/mobile-img.jpg";
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

function CreateAccount() {
    return (
        <div className="wrapper_section">
            <main className="create-account-main">
                <div className="logo_title text-center pt-4">
                    <h2 className="logo_text">Codalien</h2>
                </div>
                <section className="slider">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                                <Carousel fade className="mt-5">
                                    <Carousel.Item >
                                        <div className="slider-img-box mx-auto">
                                            <img
                                                className="d-block w-100 h-100"
                                                src={MobilePhone}
                                                alt="Second slide"
                                            />
                                        </div>
                                        <Carousel.Caption className="mt-4">
                                            <h5>Find and book your <span className="d-block">favorite meeting rooms</span></h5>
                                            <p>Reserve a place to work at any of our <span className="d-block">workplace anytime</span></p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="slider-img-box mx-auto">
                                            <img
                                                className="d-block w-100 h-100"
                                                src={MobilePhone}
                                                alt="Second slide"
                                            />
                                        </div>
                                        <Carousel.Caption className="mt-4">
                                            <h5>Find and book your <span className="d-block">favorite meeting rooms</span></h5>
                                            <p>Reserve a place to work at any of our <span className="d-block">workplace anytime</span></p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="slider-img-box mx-auto">
                                            <img
                                                className="d-block w-100 h-100"
                                                src={MobilePhone}
                                                alt="Second slide"
                                            />
                                        </div>
                                        <Carousel.Caption className="mt-4">
                                            <h5>Find and book your <span className="d-block">favorite meeting rooms</span></h5>
                                            <p>Reserve a place to work at any of our <span className="d-block">workplace anytime</span></p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>

                             <div className="btn-container mx-lg-5 mx-md-5 mx-sm-5 mx-0 mt-5 px-3">
                                 <button className="create_account_btn sign_in_btn"><Link to="/meetingroom">Create Account</Link></button>
                             </div>
                             <div className="btn-container sign_in_container mt-4 mx-lg-5 mx-md-5 mx-sm-5 mx-0 mb-5 px-3">
                             <button className="create_account_btn sign_in sign_in_btn"><Link to="/login">Sign In</Link></button>
                             </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
export default CreateAccount;