import axios from 'axios'
//const axios=require('axios')
//const Url = 'http://localhost:5000/api/graphql'
const Url ='https://admin.catools.dc1.codalien.net/api/graphql'


async function ApiService(query,variables){
  let res={}
  
  try {
     res= await axios.post(Url,{
     query:query,
     variables:variables
     },
     {
        headers: {
          'Content-Type': 'application/json'
         }
     }) 
     console.log(res.data.data);
  }catch(err){
     console.log('api error', err)
     return res.data;
  }
  return res.data.data;

}

export default ApiService;
//exports=module.exports=ApiService

