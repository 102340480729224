import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import Header from "../header";

function EmployeeLogin() {
    return (
        <div className="wrapper_section">
            <div className="login_page_main">
              <Header title="CODALIEN" icon="person" secondIcon="question_mark" />
            <div className="login_section d-flex">
                <form name="login_form " className="pt-4 login_form " autocomplete="off">
                    <div className="box-authentication px-3">
                        <input placeholder="Email Address" type="text" className="input_field  mb-3"/>
                        <input placeholder="Password" type="password" className="input_field mb-2"  autocomplete="new-password"/>
                        <div className="mb-5 d-flex justify-content-end"><Link className="forgot_password" to="/createaccount">Forgot your password ?</Link></div>
                        <div className="btn-container mt-5 px-3">
                        <button className="sign_in_btn"><Link className="text-white" to="/choicecards">Log In</Link></button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}
export default EmployeeLogin;