import React  from "react";
import "./style.scss";
import Header from "../header";
import MeetingRoom from "../../assets/img/meetingroom.svg"
import AttendanceIcon from "../../assets/img/attendance.svg"
import LeaveRequset from "../../assets/img/leaverquest.svg"
import { Link } from "react-router-dom";

// import IMAGES from "../../assets/index";

function ChoiceCards() {
const choiceData =[
    {title : "Meeting Rooms" , imgIcon : MeetingRoom , route: '/meetingroom' },
    {title : "Attendance" , imgIcon : AttendanceIcon , route: '/attendance'},
    {title : "Leave Request" , imgIcon :LeaveRequset , route: '/leaverequest'}
]
  return (
    <div className="wrapper_section">
     <Header title="CODALIEN" icon="person" secondIcon="question_mark" class1="col-6" class2="col-6" />
      <div className="what_we_want_section">
          <div className="container">
              <div className="row">
              {choiceData.map((Item) => (
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-4">
                      <Link to= {Item.route}>
                      <div className="choice_card">
                          <button>{Item.title}</button>
                          <div className="data p-5">
                          <div className="img_container">
                              <img src={Item.imgIcon} alt="meetingroom"/>
                          </div>
                          </div>
                      </div>
                      </Link>
                  </div>
              )
              )}
              </div>
          </div>
      </div>
    </div>
  );
}
export default ChoiceCards;
