import ApiService from "./apiService";
//const ApiService=require("./apiService")
export default class RoomService{
//module.exports=class RoomService{

    static async getAllRooms(){
      const results = await ApiService(`{
          rooms{
              maxPersonAllowed
              image{
                url
              }
              floor
             Meeting_Room_Name,
            MeetingRooms
            }
        }`)
        return results;
    }

    static async getRoomByRoomNo(roomno){
      const results=await ApiService(`{
        rooms(where:{MeetingRooms:{equals:${roomno}}}){
          Meeting_Room_Name
          maxPersonAllowed
          floor
          id
          image{
            url
          }
        }
        }`
      )
      return results;
    }

    static async deleteRoom(Roomnumber){
      const results = await ApiService(`mutation{
            deleteRoom(where:{Meeting_Room:${Roomnumber}}){
              floor,
              Meeting_Room
            }
        }`)
      return results;
    }
}


