import React, { useState } from "react";
import "./login.scss";
import { Link } from "react-router-dom";
import Header from "../header";
import UserService from "../../services/userService";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function Login() {

    const [validationUser,setValidationUser]=useState({});
    const [show, setShow] = useState(false);
    const [errorMsg,setErrorMsg]=useState("some error occured while logging in");
    
    async function handleClick(){
        var username = document.getElementById("uname").value;
        var password = document.getElementById("pass").value;
        try{
            UserService.ValidateUser(username,password).then((data)=>{
                setValidationUser(data);    
            if (validationUser && validationUser.authenticateUserWithPassword && validationUser.authenticateUserWithPassword.__typename==="UserAuthenticationWithPasswordSuccess"&&validationUser.token){
                sessionStorage.setItem('accessToken',validationUser.token);
                window.location.replace("/meetingroom");
            }else if(validationUser && validationUser.authenticateUserWithPassword && validationUser.authenticateUserWithPassword.__typename==="UserAuthenticationWithPasswordFailure") {
                setErrorMsg("Invalid User Id/Password")
                setShow(true);
            }
            }).catch(err=>{throw new Error(err)})   
        }catch(err){
            setErrorMsg(`error while signing in.. `);
            setShow(true);
        }
    }

    return (
        <div className="wrapper_section">
            <div className="login_page_main">
            
            <ToastContainer position="top-end">
            <Toast onClose={()=>{ setShow(false)}} show={show} delay={2000} autohide bg="Primary ">
                <Toast.Header style={{backgroundColor:"red", color:"white"
            }}>
                    <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                    />
                    <strong className="me-auto">Authentication Failed</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{errorMsg}</Toast.Body>
            </Toast>
            </ToastContainer>
            
                <Header  title="Sign In" class1="col-12" class2=""/>
            <div className="login_section d-flex">
                <p className=" pt-lg-5 pt-md-5 pt-sm-0 pt-0">
                    Book an office space , meeting room or <span className="d-block">conference room at any of our 5,500+ </span>location worldwide! it's fast and easy.
                </p>
                    <div className="box-authentication px-lg-3 px-md-3 px-sm-5 px-5">
                        <input placeholder="Email Address" type="text" className="input_field  mb-3" id="uname"/>
                        <input id="pass" placeholder="Password" type="password" className="input_field mb-2"  autocomplete="new-password"/>
                        <div className="mb-5 d-flex justify-content-end"><Link className="forgot_password" to="/createaccount">Forgot your password ?</Link></div>
                        <div className="btn-container mt-5 px-3">
                        <button className="sign_in_btn " onClick={handleClick}>Sign In</button>
                        </div>
                        <div className="btn-container sign_in_container mt-4 mb-5 px-3">
                        <button className="create_account_btn sign_in sign_in_btn"><Link to="/emplogin">Employee Login</Link></button>
                        </div>
                    </div>
                
            </div>
            </div>
        </div>
    );
}
export default Login;