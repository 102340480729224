import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { redirect, useLocation } from "react-router-dom";
import AuthenticationService from "../../services/authenticationService";
import Header from "../header";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function MeetingDetails() {
  const [tabKey, initTabKey] = useState("one");
  const location = useLocation();
  const [show,setShow]=useState(false);
  const [errorMsg,setErrorMsg]=useState("some error occured while logging in");
  if(location && location.state){
    var { booking } = location.state;
    console.log(booking);
    var day = "";
    var Time=booking.Timing.slice(11);
    var endTime=booking.endTiming.slice(11);
    var date = booking.Timing.slice(0,10);
    var meetingType =  booking.meeting_type;      
    var meetingsubject = booking.subject;
  }else{
    setErrorMsg("please login again");
    setShow(true);
    setTimeout(() => {
      navigate('/login', { replace: true });
    }, 1000);
  }
  const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
 const navigate=useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let Sessiontoken=sessionStorage.getItem('accessToken');
        const isAuthenticated=await AuthenticationService.checkAuthentication(Sessiontoken);
        console.log(isAuthenticated);
        if (isAuthenticated===false){
          setErrorMsg("please login first");
          setShow(true);
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setErrorMsg(error);
        setShow(true);
      }
    };
    fetchData();
  }, []);

  function getdaybydate() {
    const birthday = new Date(date);
    day= birthday.getDay();
  }

  function getDateRepresentation(){
    const event = new Date(date.slice(0,10));
    return event.toUTCString().slice(0,-12);
  }

  function getMeetingRoomName(){
    return `Meeting Room Number ${booking.Meeting_Room}`

  }


  
  getdaybydate();

  return (
    <div>
      <Header
        title="CODALIEN"
        icon="person"
        secondIcon="question_mark"
        class1="col-6"
        class2="col-6"
      />
      <section className="meeting_details_section">  
      <ToastContainer position="top-end">
          <Toast onClose={()=>{ setShow(false)}} show={show} delay={3000} autohide bg="Primary ">
              <Toast.Header style={{backgroundColor:"red", color:"white"}}>
                  <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                  />
                  <strong className="me-auto">Authentication Failed</strong>
                  <small>now</small>
              </Toast.Header>
              <Toast.Body>{errorMsg}</Toast.Body>
          </Toast>
      </ToastContainer>
        <div className="container">
          <div className="row justify-content-center">
            {/* <div class="time-container"> */}
            <div className="col-lg-7">
              <div className=" time-container d-flex">
                <div className="scheduled_date pt-2">
                  <label className="d-block text-center">{Days[day]}</label>
                  <label className="d-block py-1 text-center"> {date.slice(-2)}</label>
                  <label className="d-block year pt-1 pb-2 text-center">
                    { date.slice(0,4)}
                  </label>
                </div>
                <div className="interview-info ps-4">
                  <p className="mt-3 mb-1">{meetingType}</p>
                  <h4>{meetingsubject}</h4>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <Tabs activeKey={tabKey} onSelect={(e) => initTabKey(e)}>
                <Tab eventKey="one" title="Details">
                  <div className="details_section">
                    <h5 class="interview-heading mt-5 mb-4">Members</h5>
                    <div className="members_conatiner d-flex justify-content-between align-items-center">
                      <div class="person-icon-box">
                        <span class="person-icon material-symbols-outlined">
                          {" "}
                          person{" "}
                        </span>
                        <span class="person-icon material-symbols-outlined">
                          {" "}
                          person{" "}
                        </span>
                        <span class="person-icon material-symbols-outlined">
                          {" "}
                          person{" "}
                        </span>
                        <span class="person-icon material-symbols-outlined">
                          {" "}
                          person{" "}
                        </span>
                        <span class="person-icon material-symbols-outlined">
                          {" "}
                          person{" "}
                        </span>
                        <span class="person-icon material-symbols-outlined">
                          {" "}
                          person{" "}
                        </span>
                      </div>
                      <span className="memebercount">+6</span>
                    </div>
                    <div class="interview-schedule-box d-flex justify-content-start align-items-center mt-4">
                      {/* <p> */}
                      <span class="material-symbols-outlined align-text-bottom pe-lg-3 pe-md-3 pe-sm-2 pe-2">
                        schedule{" "}
                      </span>
                      {`${Time.slice(0,5)}-${endTime.slice(0,5)}`}
                      {/* </p> */}
                    </div>
                    <div class="interview-schedule-box d-flex justify-content-start align-items-center mt-4">
                      {/* <p> */}
                      <span class="material-symbols-outlined align-text-bottom pe-lg-3 pe-md-3 pe-sm-2 pe-2">
                        date_range{" "}
                      </span>
                      {getDateRepresentation(date)}
                      {/* </p> */}
                    </div>
                    <div class="interview-schedule-box d-flex justify-content-start mt-4">
                      {/* <p class="mb-0"> */}
                      <span class="material-symbols-outlined align-text-bottom pe-lg-3 pe-md-3 pe-sm-2 pe-2 mt-2">
                        location_on{" "}
                      </span>
                      <span className="text-start">
                        {getMeetingRoomName()}
                        <label className="d-block">
                           1st Floor{/**/}
                        </label>
                      </span>
                      {/* </p> */}
                    </div>
                    <div class="interview-schedule-box d-flex justify-content-start align-items-center mt-4">
                      {/* <p> */}
                      <span class="material-symbols-outlined align-text-bottom  pe-lg-3 pe-md-3 pe-sm-2 pe-2">
                        article{" "}
                      </span>
                     {booking.agenda}
                      {/* </p> */}
                    </div>
                  </div>
                  {/* <div class="border-top"> */}
                  <div className="agenda-box  mt-5 px-2">
                    <h4 className="interview-heading mb-4">Agenda</h4>
                    <div className="interview_session d-flex justify-content-start mb-3 align-items-center">
                      <div class="custom-checkbox">
                        <input type="checkbox" id="checkbox-1" />
                        <label for="checkbox-1"></label>
                      </div>
                      <span for="flexCheckDefault">{`${booking.meeting_type} session`}</span>
                    </div>
                    <div class="interview_session d-flex justify-content-start mb-3 align-items-center">
                      <div class="custom-checkbox">
                        <input type="checkbox" id="checkbox-2" />
                        <label for="checkbox-2"></label>
                      </div>
                      <span for="flexCheckChecked">{booking.agenda }</span>
                    </div>
                  </div>
                  {/* </div> */}
                  <div class="remind-me-box px-2 agenda-box d-flex align-items-center justify-content-between mt-5">
                    <h4 class="interview-heading mb-4">
                      <span class="bell-icon material-symbols-outlined align-text-bottom">
                        notifications_active
                      </span>
                      Remind Me
                    </h4>
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="swipe round"></span>
                    </label>
                  </div>
                </Tab>
                <Tab eventKey="two" title="Files">
                  <p>Tab 2</p>
                </Tab>
                <Tab eventKey="three" title="Notes">
                  <p>Tab 3</p>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MeetingDetails;
