import { useEffect } from "react";
import "./style.scss";
import CompanyLogo from "../../assets/img/logo.png"
import { useNavigate } from "react-router-dom";

// import IMAGES from "../../assets/index";

function SplashScreen() {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 3000);
      },[]);
    return (
        <div className="wrapper_section">
            <div className="splash_screen_container d-flex justify-content-center align-items-center">
                <div className="inner_data">
                    <div className="logo">
                        <img src={CompanyLogo} alt="logo" />
                    </div>
                    <h1 className="text_content pt-4">Providing Solutions , Building MasterPieces</h1>
                </div>
            </div>
        </div>
    );
}
export default SplashScreen;