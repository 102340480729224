import ApiService from "./apiService";
import AuthenticationService from "./authenticationService";
// const ApiService=require("./apiService");
// const AuthenticationService=require("./authenticationService")

export default class UserService {
//module.exports=class UserService {
  static async getAllUsers() {
    const results = await ApiService(`{
                users{
                        name
                        password{
                        isSet
                        
                        }
                        email
                        isAdmin
                }
                }`)
      return results;
  }

  static async getUserByUsername(email){
    const results = await ApiService(`{
      user(where:{email:"${email}"}){
              name
              email
              isAdmin
              id
        }
    }`)
    return results;
  }

  static async ValidateUser(username,password){
    const result=await ApiService(
      `mutation {
        authenticateUserWithPassword(email:"${username}", password:"${password}"){
            __typename
        }
      }`
    )
    try{
      let token=await this.generateToken(result,username);
      result.token=token;
    }catch(err){
      console.log("usr-er")
      throw new Error(err);
    }
    return result;
  }

  static async generateToken(results,username) {
    try {
      if (results.authenticateUserWithPassword.__typename==='UserAuthenticationWithPasswordSuccess'){
        let user=await this.getUserByUsername(username);
        return await AuthenticationService.generateToken(user).then((token)=>{
          return token;
        }).catch(err=>{throw new Error(err)})
    }}catch(err){
      console.log("usr-er2")
      throw new Error(err);
    }
  }

  static async addUser(name, email, phone, password, isAdmin,isEmployee) {
    const results = await ApiService(`mutation{
          createUsers(data:{isAdmin:${isAdmin},
                            email:"${email}",
                            name:"${name}",
                            password:"${password}",
                            isEmployee:${isEmployee}
                          }){
                id,
                name,
                email,
                isEmployee,
                isAdmin
          }
      }`)
    return results;
  }

  static async deleteUserByEmail(email) {
    const results = await ApiService(`mutation{
                deleteUser(where:{
                  email:"${email}"
                }){
                  name
                }
        }`)
    return results;
  }
}
