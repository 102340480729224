import ApiService from "./apiService";

export default class BookingService {
  static async getallbookings() {}

  static async createbooking(
    name,
    agenda,
    email,
    phone,
    duration,
    timing,
    subject,
    room,
    meetingtype
  ) {
    const results = await ApiService(`mutation{
            createBooking(data:{
              name:"${name}",
              phone:"${phone}",
              email:"${email}",
              Timing:"${timing}",
              Duration:${duration},
              Meeting_Room:${room},
              meeting_type:"${meetingtype}",
              agenda:"${agenda}",
              subject:"${subject}"   
            }){
              name,
              Timing
            }
          }`);
    return results;
  }


  static async getBookingsByTimeForRoom(starttime,roomNumber,endtime) {
    //console.log(roomNumber,starttime,endtime);
    const results = await ApiService(`{
      bookings(where:
        {OR:
          [
            {AND:[{Meeting_Room:{equals:${roomNumber}}},{Timing:{gte:"${starttime}"}}, {Timing:{lte:"${endtime}"}}]},
            {AND:[{Meeting_Room:{equals:${roomNumber}}},{endTiming:{gte:"${starttime}"}}, {endTiming:{lte:"${endtime}"}}]},
            {AND:[{Meeting_Room:{equals:${roomNumber}}},{Timing:{lte:"${starttime}"}}, {endTiming:{gte:"${endtime}"}}]}
          ]
        }){
        name,
        Duration,
        agenda
        Timing,
        Meeting_Room,
        meeting_type
      }
    }`)
   return results;
    //return [];
  }

  static async getBookingsByEmailAndDate(email,date) {
    const cd=this.convertToISO(date);
    const nd=this.convertEndDayToISO(date);
    const results = await ApiService(`{
        bookings(where:{ AND: [{email:{contains:"${email}"}}, {Timing:{gt:"${cd}"}},{Timing:{lt:"${nd}"}}]}){
          name,
          subject,
          Meeting_Room,
          Timing,
          phone,
          email,
          Duration,
          meeting_type,
          agenda,
          endTiming
        }
      }`);
    return results;
  }


  static async checkavailabilty(duration,timing,roomno){
      console.log(timing,roomno,duration);
      let ans={isValid:false
               ,status:"" };
       let time=timing;
       console.log(time);
       let endTime=await this.calculateendTime(duration,timing);
       console.log(endTime);
       if (timing.length>22 && roomno !== 0){
           return BookingService.getBookingsByTimeForRoom(time,roomno,endTime).then((data)=>{
               console.log(data)
               if(data.bookings && data.bookings.length===0){
                   console.log("booking available");
                   ans.status="booking available";
                   ans.isValid=true;
                   ans.data=data;
                   return ans;
               }else{
                   console.log("no booking available at that time")
                   ans.status="booking unavailable";
                   ans.isValid=false;
                   ans.data=data;
                   return ans;
               }
           })
       }else {
        ans.isValid=false;   
        ans.status.concat("invalid Properties/Values");
        return ans;
      }
      
   }


   static async calculateendTime(duration,date){
    //let date =await this.calculateGMTTime(timing);
    var h=duration/60;
    var m=duration%60;
    var newDate=new Date(date);
    newDate = new Date(newDate.setMinutes(newDate.getMinutes() + m));
    newDate = new Date(newDate.setHours(newDate.getHours() + h));
    console.log(newDate.toISOString());
    return newDate.toISOString(); 
  }

  // static async calculateGMTTime(timing){
  //   let date = new Date(timing)
  //     date=date.toUTCString().toString();
  //     let IST=date.slice(0,-3);
  //     let GMT=new Date(IST).toISOString();
  //     console.log(GMT);
  //     return GMT;
  // }

  static async deleteBookingByEmail(email) {
    const results = await ApiService(`mutation{
          deleteBooking(where:{email:"${email}"}){
                    name
          }
        }`);
    return results;
  }

  static getdatetoday(date) {
    if (date.length>22){
      const rkhni = date.slice(0, 10);
      var modifykrni = date.slice(10);
      modifykrni = modifykrni.replace(/\d/g, "0");
      date = rkhni + modifykrni;
    }
    return date;
  }

  static getnextdate(date) {
    if (date.length>22){
      const rkhni = date.slice(0, 10);
      var modifykrni = date.slice(10);
      let nextday = parseInt(rkhni.slice(-2)) + 1;
      if (nextday<10){
        nextday=`0${nextday}`;
      }
      const rk = date.slice(0, 8) + nextday;
      modifykrni = modifykrni.replace(/\d/g, "0");
      date = rk + modifykrni;
    }
    return date;
  } 

  static convertToISO(date){
    date=date.toString().slice(0,-31);
    date=date.concat('Z');
    date=new Date(date).toISOString()
    console.log(date);
    return date
  }

  static convertEndDayToISO(date){
    date=date.toString().slice(0,-31);
    date=date.concat('Z');
    date=new Date(date).toISOString();
    let dt=parseInt(date.slice(8,10));
    dt=dt+1;
    if(dt<10){
        dt=`0${dt}`;
    }
    return `${date.slice(0,8)}${dt}${date.slice(10)}`;
  }

}
