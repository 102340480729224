import StatelessMiddleware from "../Middleware/StatelessMiddleware";
//const StatelessMiddleware=require("../Middleware/StatelessMiddleware");

export default class AuthenticationService{
//exports=module.exports= class AuthenticationService{
    
    static async generateToken(user) {
        let ACCESS_TOKEN_EXPIRY=7 * 24 * 60 * 60 * 1000;
        let userID = user.user ? user.user.id : null;
        let username = user.user ? user.user.email:null;
        let time = Date.now();
        if (userID !== null) {
            try{
                let token=await StatelessMiddleware.generateAccessToken({
                    _id: userID,
                    expiry: time + ACCESS_TOKEN_EXPIRY,
                    username: username
                })
                return token;
            }catch(err){
                console.log("Authwer:-",err);
                throw new Error(err);
            }
        } else throw new Error("can't generate token");
    }

    static async checkAuthentication(Sessiontoken){
        console.log(Sessiontoken);
        if (Sessiontoken){
            try {
               let sessionData=JSON.parse(await StatelessMiddleware.decrypt(Sessiontoken));
               console.log("sd:-",sessionData);
               if (sessionData && this.checkTokenExpiry(sessionData)){
                    return true;
               }else{
                    return false;
               }
            }catch(err){
                console.log("authusr-er")
                throw new Error(`please login again/ session expired :- ${err}`);
            }
        }else{
            return false;
        }
    }

    static async getUserDataByToken(Sessiontoken){
        console.log(Sessiontoken);
        if (Sessiontoken){
            try {
               let sessionData=JSON.parse(await StatelessMiddleware.decrypt(Sessiontoken));
               console.log("sd:-",sessionData)
               return sessionData;
            }catch(err){
                throw new Error(`please login again/ session expired :- ${err}`);
            }
        }else{
            throw new Error(`please login again/ session expired }`);
        }
    }

    static async decryptToken(token){
        if (token !== null) {
            const data=await StatelessMiddleware.decrypt(token)
            return data;
        } else throw new Error("unable to login/credentials error");
    }

    static async checkTokenExpiry(sessionData){
        if(sessionData && sessionData.expiry> Date.now()){
            console.log("token not expired");
            return true;
        }else{
            console.log("token expired");
            return false;
        }
    }

}