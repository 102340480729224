import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../header";
import Footer from "../footer";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RoomService from "../../services/roomService";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import AuthenticationService from "../../services/authenticationService";

function MeetingRoom() {

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [errorMsg,setErrorMsg]=useState("some error occured while logging in");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let Sessiontoken=sessionStorage.getItem('accessToken');
        const isAuthenticated=await AuthenticationService.checkAuthentication(Sessiontoken);
        console.log(isAuthenticated);
        if (isAuthenticated===true){
          const res=await RoomService.getAllRooms();
          setData(res);
          console.log(data);
        }else{
          setErrorMsg("please login first");
          setShow(true);
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setErrorMsg(error);
        setShow(true);
      }
    };
    fetchData();
  },[]);

  return (
    <div className="wrapper_section">
      <Header title="CODALIEN" icon="person" secondIcon="question_mark" class1="col-6" class2="col-6" />
      <main className="meeting-room-section">
      <ToastContainer position="top-end">
          <Toast onClose={()=>{ setShow(false)}} show={show} delay={3000} autohide bg="Primary ">
              <Toast.Header style={{backgroundColor:"red", color:"white"}}>
                  <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                  />
                  <strong className="me-auto">Authentication Failed</strong>
                  <small>now</small>
              </Toast.Header>
              <Toast.Body>{errorMsg}</Toast.Body>
          </Toast>
      </ToastContainer>
        <div className="container mb-5 pb-5">
          <div className="row justify-content-between align-items-baseline my-lg-5 my-md-5 my-sm-4 my-4">
              <div className="col-6">
              <h2 className="heading mb-0">Meeting Rooms</h2>
              </div>
              <div className="icon_container col-6">
              <button className="border border-0"><span className="material-symbols-outlined">filter_alt</span></button>
              <button className="border border-0"><span className="material-symbols-outlined"> schedule </span></button>
              </div>
          </div>
          {!data || !data.rooms ? (
            <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
                ):(
          data.rooms.map((Item ,index) => (
            <div className="cards mb-4">
              <div className="row">
                <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                  <div className="meeting-room-img-box">
                    <img
                      src={Item.image.url}
                      className="img-fluid"
                      alt="Meeting room "
                    />
                  </div>
                </div>
                <div className="col-7 col-sm-7 col-md-7 col-lg-7">
                  <div className="card-body text-left">
                    <h5 className="card-title my-lg-4 my-md-4 my-sm-3 my-3">{Item.Meeting_Room_Name}</h5>
                    <p className="card-text mb-lg-4 mb-md-4 mb-sm-2 mb-2">{Item.floor}</p>
                    <p className="card-text mb-lg-4 mb-md-4 mb-sm-2 mb-2">{Item.maxPersonAllowed}</p>
                    <div className="d-flex align-items-center  my-lg-4 my-md-4 my-sm-3 my-3">
                    <button className="border border-0 me-lg-3 me-md-3 me-sm-2 me-2 cards-icon_container">
                      <span
                        className="card-icon material-symbols-outlined"
                        id="card-icon"
                      >
                       wifi 
                      </span>
                    </button>
                    <button className="border border-0 me-lg-3 me-md-3 me-sm-2 me-2 cards-icon_container">
                      <span
                        className="card-icon material-symbols-outlined"
                        id="card-icon"
                      >
                        videocam
                      </span>
                    </button>
                    <button className="border border-0 me-lg-3 me-md-3 me-sm-2 me-2 cards-icon_container">
                      <span
                        className="card-icon material-symbols-outlined"
                        id="card-icon"
                      >
                        mic
                      </span>
                    </button>
                    <button className="border border-0 me-lg-3 me-md-3 me-sm-2 me-2 cards-icon_container">
                      <span className="material-symbols-outlined card-icon" id="card-icon">
                       local_cafe
                      </span>
                    </button>
                    <button className="border border-0 book-room-btn">
                      <Link
                        to="/bookrooms"
                        class="text-decoration-none text-white"
                        state={{ meetingroom: Item.MeetingRooms}}
                      >
                        Book
                      </Link>
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
}
export default MeetingRoom;
