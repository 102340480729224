import { useState } from "react";
import { Fade } from "react-bootstrap";
import "./style.scss";

function Footer() {
    const dataFooter = [
       {"TabIcon": "home", "TabText": "Home"},
       {"TabIcon": "star", "TabText": "Details"},
       {"TabIcon": "calendar_month", "TabText": "Calendar"},
       {"TabIcon": "call", "TabText": "Contact"}
    ]
    const [isActive, setIsActive] = useState(0);

    // const handleClick = (type) => {
    //     setIsActive(type); 
    // };
    
    return (

        <div className="footer_container">
            <footer className="footer">
                <div className="container">
                    <div className="row align-items-center">
                        {dataFooter.map((Item, index) => (
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <div className="footer-icon-box text-center">
                                    <button className={`footer-tab ${isActive ===index ? ' active' : ''}`} onClick={() => setIsActive(index)}>
                                        <span
                                            className={"material-symbols-outlined-footer material-symbols-outlined"}>
                                            {Item.TabIcon}
                                        </span>
                                        <label className="tab_text">{Item.TabText}</label>
                                    </button>
                                </div>
                            </div>
                        )
                        )}
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default Footer;