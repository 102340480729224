import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import "./style.scss";
import Header from "../header";
import 'react-calendar/dist/Calendar.css';
import BookingService from '../../services/bookingService';
import Spinner from 'react-bootstrap/Spinner';
import { Link,useNavigate } from 'react-router-dom';
import AuthenticationService from '../../services/authenticationService';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
// import IMAGES from "../../assets/index";

function Schedule() {
  // const meetingRoomData = [
  //   {"Time" : "08:00" , "title" :"Interview Meeting" , "dots" : "more_vert" , "Cause" :"Interview with client" ,  "PersonIcon" :"person" ,  "Count" :"+6"},
  //   {"Time" : "09:00" , "title" :"Business Meeting" ,  "dots" : "more_vert" , "Cause" :"Marketing Startegy" ,  "PersonIcon" :"person" ,  "Count" :"+6"},
  //   {"Time" : "10:00" , "title" :"Design Meeting" ,  "dots" : "more_vert" , "Cause" :"Graphic Design Workshop" ,  "PersonIcon" :"person" ,  "Count" :"+6"},
  // ]
  const navigate=useNavigate();
  const color=[
    "#03adfc","#d60b23","#033dfc","#7e0bd6","#d60bbb","#d60b23","#d6c20b","#52d60b","#d60bb4"
  ]
  const [errorMsg,setErrorMsg]=useState("some error occured while logging in");
  const [Datevalue, setDateValue] = useState(new Date());
  const [data, setData] = useState({});
  const [show,setShow]=useState(false)


  function onDateChange(e){
    setDateValue(e);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        let Sessiontoken=sessionStorage.getItem('accessToken');
        const isAuthenticated=await AuthenticationService.checkAuthentication(Sessiontoken);
        console.log(isAuthenticated);
        if (isAuthenticated===true){
          const user=await AuthenticationService.getUserDataByToken(Sessiontoken);
          if(user&&user.username){
            const res=await BookingService.getBookingsByEmailAndDate(user.username,Datevalue);
            setData(res);
            console.log(data);
          }else{
            setErrorMsg("unable to find bookings");
            setShow(true);
          }
        }else{
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setErrorMsg(error);
        setShow(true);
      }
    };
    fetchData();
  }, [Datevalue]);
  
  return (
    <div>
      <div class="schedule_interview pb-5">
      <Header title="CODALIEN" icon="person" secondIcon="question_mark" class1="col-6" class2="col-6" />
      <ToastContainer position="top-end">
          <Toast onClose={()=>{ setShow(false)}} show={show} delay={3000} autohide bg="Primary ">
              <Toast.Header style={{backgroundColor:"red", color:"white"}}>
                  <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                  />
                  <strong className="me-auto">Authentication Failed</strong>
                  <small>now</small>
              </Toast.Header>
              <Toast.Body>{errorMsg}</Toast.Body>
          </Toast>
      </ToastContainer>
        <div class="container">
          <div class="row justify-content-center count_down">
            <div class="col-12 col-lg-6 wrapper mt-lg-5 mt-md-5 mt-sm-3 mt-4">
                <div className="calander">
                <Calendar onChange={onDateChange} value={Datevalue} />
                </div>
            </div>
              <h4 class="heading-timeline mt-5 mb-4">Today's Timeline</h4>
              <div class="col-12">
                {!data||!data.bookings?(
                  <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                ):(data.bookings.map((Item,idx)=>{
                      return(<Link to="/meetingdetails" state={{booking:Item}}><div class="schedule_card ">
                      <div class="row">
                        <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                          <span className="scheduled_time">{Item.Timing.slice(11,16)}</span>
                        </div>
      
                        <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                          <div className='cards'>
                          <div class="interview-card mt-4 " style={{backgroundColor:`${color[idx]}`}}>
                            <div class="d-flex justify-content-between">
                              <span class="meeting_label white-color text-white">
                                {Item.subject}
                              </span>
      
                              <span class="white-color material-symbols-outlined menu_icon text-white">
                               {Item.dots}
                              </span>
                            </div>
                            <h2 class="white-color card-tittle text-white" id={Item.meeting_type}>
                               {Item.meeting_type}
                            </h2>
                            <div class="d-flex justify-content-between">
                              <div class="d-flex">
                              {Array.from(Array(6), (e, i) =>{
                                <div class="img-container">
                                  <span class="material-symbols-outlined">
                                    {Item.PersonIcon}
                                  </span>
                                </div>
                              })}
                              </div>
                              <span class="number-count">+6</span>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div></Link>)
                    })
                )}
              {/* {meetingRoomData.map((Item,idx) => (
              <div class="schedule_card">
                <div class="row">
                  <div class="col-2 col-sm-2 col-md-2 col-lg-2">
                    <span className="scheduled_time">{Item.Time}</span>
                  </div>

                  <div class="col-10 col-sm-10 col-md-10 col-lg-10">
                    <div className='cards'>
                    <div class="interview-card mt-4">
                      <div class="d-flex justify-content-between">
                        <span class="meeting_label white-color text-white">
                          {Item.title}
                        </span>

                        <span class="white-color material-symbols-outlined menu_icon text-white">
                         {Item.dots}
                        </span>
                      </div>
                      <h2 class="white-color card-tittle text-white" id={Item.Cause}>
                        {Item.Cause}
                      </h2>
                      <div class="d-flex justify-content-between">
                        <div class="d-flex">
                          <div class="img-container">
                            <span class="material-symbols-outlined">
                              {Item.PersonIcon}
                            </span>
                          </div>
                          <div class="img-container">
                            <span class="material-symbols-outlined">
                              {Item.PersonIcon}
                            </span>
                          </div>
                          <div class="img-container">
                            <span class="material-symbols-outlined">
                              {Item.PersonIcon}
                            </span>
                          </div>
                          <div class="img-container">
                            <span class="material-symbols-outlined">
                              {Item.PersonIcon}
                            </span>
                          </div>
                          <div class="img-container">
                            <span class="material-symbols-outlined">
                              {Item.PersonIcon}
                            </span>
                          </div>
                          <div class="img-container">
                            <span class="material-symbols-outlined">
                              {Item.PersonIcon}
                            </span>
                          </div>
                        </div>
                        <span class="number-count">{Item.Count}</span>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Schedule;
